import React from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';

ReactModal.setAppElement('#root');

function Modal({ isOpen, onRequestClose, contentLabel, fullWidth, children }) {
    const overlayClassName = classnames('uk-modal uk-open uk-display-block', { 'uk-modal-container': fullWidth });
    const contentClassName = classnames('uk-modal-dialog');

    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={contentLabel}
            overlayClassName={overlayClassName}
            className={contentClassName}
            htmlOpenClassName="uk-modal-page"
        >
            {children}
        </ReactModal>
    );
}
export default Modal;
