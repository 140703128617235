import axios from 'axios';
import _ from 'lodash';
import { API_URL } from '../config';

function isFunction(functionToTest) {
    return !!(functionToTest && functionToTest.constructor && functionToTest.call && functionToTest.apply);
}

export function apiPost(contextUrl, payload, cb) {
    let options = {};
    if (isFunction(payload)) {
        cb = payload;
        payload = {};
    }
    if (!isFunction(cb)) cb = () => {};

    axios.post(API_URL + contextUrl, payload, options).then(
        response => {
            if (response && response.data && response.data.error && response.data.error !== true) response.data.error = false;
            cb(null, response.data);
        },
        error => {
            cb(_.get(error, 'response.error', true), _.get(error, 'response.data', {}));
        }
    );
}

export function apiPut(contextUrl, payload, cb) {
    let options = {};
    if (isFunction(payload)) {
        cb = payload;
        payload = {};
    }
    if (!isFunction(cb)) cb = () => {};

    axios.put(API_URL + contextUrl, payload, options).then(
        response => {
            if (response && response.data && response.data.error && response.data.error !== true) response.data.error = false;
            cb(null, response.data);
        },
        error => {
            cb(_.get(error, 'response.error', true), _.get(error, 'response.data', {}));
        }
    );
}

export function apiGet(contextUrl, payload, cb) {
    if (isFunction(payload)) {
        cb = payload;
        payload = {};
    }
    if (!isFunction(cb)) cb = () => {};

    axios.get(API_URL + contextUrl, { params: payload }).then(
        response => {
            if (response && response.data && response.data.error && response.data.error !== true) response.data.error = false;
            cb(null, response.data);
        },
        error => {
            cb(_.get(error, 'response.error', true), _.get(error, 'response.data', {}));
        }
    );
}

export function apiDelete(contextUrl, payload, cb) {
    if (isFunction(payload)) {
        cb = payload;
        payload = {};
    }
    if (!isFunction(cb)) cb = () => {};

    axios.delete(API_URL + contextUrl, { params: payload }).then(
        response => {
            if (response && response.data && response.data.error && response.data.error !== true) response.data.error = false;
            cb(null, response.data);
        },
        error => {
            cb(true, error);
        }
    );
}
