import _ from 'lodash';
import Button from '../form-elements/Button';
import React from 'react';

export function CookieSettingsButton({ children = 'Cookie-Einstellungen' }) {
    const handleOneTrustInformation = () => {
        if (_.isFunction(window.OneTrust?.ToggleInfoDisplay)) {
            window.OneTrust.ToggleInfoDisplay();
        }
    };
    return (
        <Button modifier="link" onClick={handleOneTrustInformation}>
            {children}
        </Button>
    );
}
