import React, { useState } from 'react';

import loadable from '@loadable/component';
import Modal from '../Modal/Modal';

const ContactForm = loadable(() => import('./ContactForm'));

function ContactModal({ children }) {
    const [open, setOpen] = useState(false);

    function openModal() {
        setOpen(true);
    }

    function closeModal() {
        setOpen(false);
    }

    return (
        <>
            {children({ openModal })}
            <Modal isOpen={open} onRequestClose={closeModal} contentLabel="Kontakt Modal">
                <ContactForm closeContactModal={closeModal} />
            </Modal>
        </>
    );
}

export default ContactModal;
