import { shouldFetch } from '../../fetchStatus';
import { FETCH_COUPONS_FAILURE, FETCH_COUPONS_REQUEST, FETCH_COUPONS_SUCCESS } from '../../actionTypes';
import { apiClient } from '../../../helpers/apiHelperV2';
import { couponsResponseSelector } from '../../selectors/standort/coupons';
import { markenStandortSelector } from '../../selectors/standort/markenStandort';
import { standortUrlSelector } from '../../selectors/url';

export const fetchCouponsIfNeeded = () => {
    return async function (dispatch, getState) {
        const state = getState();

        const standortUrl = standortUrlSelector(state);
        const markenStandortUrl = markenStandortSelector(state);
        const couponsResponse = couponsResponseSelector(state);

        if (shouldFetch(couponsResponse.status)) {
            dispatch({
                type: FETCH_COUPONS_REQUEST,
                payload: { standortUrl, standortId: markenStandortUrl?._id },
            });
            try {
                const res = await apiClient.get('/api/v1/coupons', {
                    params: {
                        standortId: markenStandortUrl?._id,
                    },
                });
                dispatch({
                    type: FETCH_COUPONS_SUCCESS,
                    payload: { standortUrl, coupons: res.data },
                });
            } catch (error) {
                dispatch({
                    type: FETCH_COUPONS_FAILURE,
                    payload: { standortUrl, error },
                });
            }
        }
    };
};
