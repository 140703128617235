import React from 'react';
import { fetchCouponsIfNeeded } from '../../../modules/actions/standort/coupons';
import { couponsResponseSelector } from '../../../modules/selectors/standort/coupons';
import { connect } from 'react-redux';
import { frontloadConnect } from 'react-frontload';
import CouponModal from '../CouponModal/CouponModal';

function CouponBanner({ coupons }) {

    const { data: coupon } = coupons
    let activeCoupon;
    if (coupon) {
        coupon.forEach((coupon) => {
            return coupon.banner.isActive ? activeCoupon = coupon : null;
        });
    }

    return (activeCoupon ?
        <div>
            <CouponModal coupon={activeCoupon}>
                {({ openCouponModal }) => (
                    <div className="">
                        <div 
                        className="uk-width-1-1 uk-background-primary uk-button-primary" 
                        style={{maxHeight: 36, marginBottom: 12, cursor: 'pointer', border: 0, height: 40}}
                        onClick={openCouponModal}>
                            <p className="uk-width-expand uk-text-light uk-text-center uk-text-large uk-text-bold" style={{padding: 4 , fontSize: 18, wordWrap: 'break-word'}}>
                            { activeCoupon?.banner?.label } 
                            </p>
                        </div>
                    </div>
                )}
            </CouponModal>
        </div>
    : null)
}

function LoadCouponDetail({ couponResponse }) {
    return (
        <CouponBanner coupons={couponResponse} />
    );
}

const frontload = async ({ fetchCouponsIfNeeded }) => {
    await fetchCouponsIfNeeded();
};

const mapStateToProps = (state) => {
    return {
        couponResponse: couponsResponseSelector(state),
    };
};

const mapDispatchToProps = { fetchCouponsIfNeeded };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(frontloadConnect(frontload)(LoadCouponDetail));
// export default CouponBanner;
