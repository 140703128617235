import React from 'react';

import { apiGet } from '../../../helpers/apiHelper';
import { BackgroundImage } from '../../components/Image/Image';
import { InternalLink } from '../../components/form-elements/Link';
import { connect } from 'react-redux';
import { categoryUrlSelector, standortUrlSelector } from '../../../modules/selectors/url';
import { hideServiceFormSelector } from '../../../modules/selectors/standort/theme';
import Spinner from '../../components/Spinner/Spinner';
import Button from '../../components/form-elements/Button';
import ContactModal from '../../components/Anfrage/ContactModal';

class CategoryLandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            foundContent: null,
        };
        this.getLandingPageContent = this.getLandingPageContent.bind(this);
    }

    getLandingPageContent() {
        apiGet(
            '/api/v1/CategoryLandingPageContent',
            {
                standortUrl: this.props.standortUrl,
                categoryUrl: this.props.categoryUrl,
            },
            (err, foundContent) => {
                if (err) console.error(err);
                else this.setState({ foundContent });
            }
        );
    }

    componentDidMount() {
        this.getLandingPageContent();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.categoryUrl !== prevProps.categoryUrl) this.getLandingPageContent();
    }

    render() {
        const { foundContent } = this.state;
        const { hideServiceForm } = this.props;

        return foundContent ? (
            <div id="categoryLandingpage">
                <div className="categoryLandingpage__header clear" dangerouslySetInnerHTML={{ __html: foundContent.header }} />
                <div className="flex">
                    <div className="side_box_container mobile" />
                    <div style={{ clear: 'both' }} />
                    <div className="categoryLandingpage__content">
                        {foundContent.content.map(content => (
                            <div className="categoryLandingpage__content__box uk-flex" key={foundContent.content.indexOf(content)}>
                                <div className="row_container uk-flex">
                                    <BackgroundImage
                                        className="content__box__image"
                                        src={`https://techno-images.s3.eu-central-1.amazonaws.com/category-landingpage/${content.image}`}
                                    />
                                    <div className="content__box__text">
                                        <div className="content" dangerouslySetInnerHTML={{ __html: content.text }} />
                                        {hideServiceForm ? (
                                            <ContactModal>
                                                {({ openModal }) => (
                                                    <Button modifier="secondary" onClick={openModal}>
                                                        Jetzt Beratungsgespräch vereinbaren
                                                    </Button>
                                                )}
                                            </ContactModal>
                                        ) : (
                                            <InternalLink modifier="secondary" to="/serviceAnfrage">
                                                Jetzt Beratungsgespräch vereinbaren
                                            </InternalLink>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        ) : (
            <div>
                <Spinner />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    standortUrl: standortUrlSelector(state),
    categoryUrl: categoryUrlSelector(state),
    hideServiceForm: hideServiceFormSelector(state),
});

export default connect(mapStateToProps)(CategoryLandingPage);
