import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { getUkButtonClassName } from './internal/ukClassName';
import { validateClassName } from './internal/customPropTypesValidators';
import _ from 'lodash';

/**
 * Themeable button.
 *
 * See the [Styleguide](http://localhost:3000/de/styleguide) for usage examples.
 */
function Button({
    children,
    className: additionalClassName,
    disabled,
    fullWidth,
    hideArrow,
    modifier = 'primary',
    onClick,
    type = 'button',
}) {
    const className = getUkButtonClassName({ modifier, fullWidth, hideArrow, additionalClassName });

    return (
        // This is the only allowed native button.
        // eslint-disable-next-line react/forbid-elements
        <button type={type} className={className} onClick={onClick} disabled={disabled}>
            {children}
        </button>
    );
}

function validateButtonOnClick(props, propName, componentName) {
    const onClick = props[propName];
    const type = props.type;

    if (type === 'submit') {
        if (!_.isNil(onClick)) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. A button with type="submit" is incompatible with \`${propName}\`.`
            );
        }
    } else {
        if (!_.isFunction(onClick)) {
            return new Error(
                `Invalid prop \`${propName}\` supplied to \`${componentName}\`. A button with type="button" must be used in combination with \`${propName}\`.`
            );
        }
    }
}

Button.propTypes = {
    children: PropTypes.node.isRequired,
    className: validateClassName,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    hideArrow: PropTypes.bool,
    modifier: PropTypes.oneOf(['default', 'primary', 'secondary', 'danger', 'text', 'link']),
    onClick: validateButtonOnClick,
    type: PropTypes.oneOf(['submit', 'button']),
};
Button.propTypes = exact(Button.propTypes);

export default Button;
